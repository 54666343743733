document.addEventListener("turbolinks:load", () => {
  $('#rubricas_suspensoes_exigibilidade_cp').on('cocoon:after-insert', function(e, insertedItem) {
      var $element = $(insertedItem.find('select'));
      add_select2($element);
  });

  $('#rubricas_suspensoes_exigibilidade_irrf').on('cocoon:after-insert', function(e, insertedItem) {
      var $element = $(insertedItem.find('select'));
      add_select2($element);
  });

  $('#rubricas_suspensoes_exigibilidade_pispasep').on('cocoon:after-insert', function(e, insertedItem) {
      var $element = $(insertedItem.find('select'));
      add_select2($element);
  });

  $('#rubricas_processos_fgts').on('cocoon:after-insert', function(e, insertedItem) {
      var $element = $(insertedItem.find('select'));
      add_select2($element);
  });
});
