document.addEventListener("turbolinks:load", () => {

  $('#dependentes_pagamento').on('cocoon:after-insert', function(e, insertedItem) {
    add_masks();
    add_select2(insertedItem.find('.select2'));

    insertedItem.find(".dependente_deduz_base_selector").on('change', function (e) {
      toggle_tipo_dependente_fields($(this));
    });
    insertedItem.find('.tipo_dependente_selector').on('change', function (e) {
      toggle_descricao_dependente_field($(this));
    });
  });

  $('#pagamentos_codigos_receita_irrf').on('cocoon:after-insert', function(e, insertedItem) {
    add_select2(insertedItem.find('.select2'));
    $('#deducoes_dependentes').on('cocoon:after-insert', function(e, insertedItem) {
      add_masks();
      add_select2(insertedItem.find('.select2'));
      add_money_masks(insertedItem.find('.masked_money'));
    });

    $('#deducoes_pensoes_alimenticias').on('cocoon:after-insert', function(e, insertedItem) {
      add_masks();
      add_select2(insertedItem.find('.select2'));
      add_money_masks(insertedItem.find('.masked_money'));
    });

    $('#deducoes_previdencias_complementares').on('cocoon:after-insert', function(e, insertedItem) {
      add_masks();
      add_select2(insertedItem.find('.select2'));
      add_money_masks(insertedItem.find('.masked_money'));
      insertedItem.find('.tipo_previdencia_complementar_selector').on('change', function (e) {
        toggle_valor_patrocinador_previdencia_field($(this));
      });
      toggle_valor_patrocinador_previdencia_field(insertedItem.find('.tipo_previdencia_complementar_selector'));
    });
  });

  $('#deducoes_dependentes').on('cocoon:after-insert', function(e, insertedItem) {
    add_masks();
    add_select2(insertedItem.find('.select2'));
    add_money_masks(insertedItem.find('.masked_money'));
  });

  $('#deducoes_pensoes_alimenticias').on('cocoon:after-insert', function(e, insertedItem) {
    add_masks();
    add_select2(insertedItem.find('.select2'));
    add_money_masks(insertedItem.find('.masked_money'));
  });

  $('#deducoes_previdencias_complementares').on('cocoon:after-insert', function(e, insertedItem) {
    add_masks();
    add_select2(insertedItem.find('.select2'));
    add_money_masks(insertedItem.find('.masked_money'));
    insertedItem.find('.tipo_previdencia_complementar_selector').on('change', function (e) {
      toggle_valor_patrocinador_previdencia_field($(this));
    });
    toggle_valor_patrocinador_previdencia_field(insertedItem.find('.tipo_previdencia_complementar_selector'));
  });

  $('#informacoes_pagamento').on('cocoon:after-insert', function(e, insertedItem) {
    add_select2(insertedItem.find('.select2'));
    add_money_masks(insertedItem.find('.masked_money'));
    load_remuneracoes_devidas(insertedItem.find('.remuneracao_devida_selector'));

    insertedItem.find('.remuneracao_devida_selector').on('change', function (e) {
      load_demonstrativos_valores($(this));
    });

    insertedItem.find('.pais_pagamento_selector').on('change', function (e) {
      toggle_pagamento_exterior_fields($(this));
    });
    toggle_pagamento_exterior_fields(insertedItem.find('.pais_pagamento_selector'));
  });

  $('#pagamento_pessoa_id').on('change', function (e) {
    load_remuneracoes_devidas();
  });

  load_remuneracoes_devidas();

  $('.remuneracao_devida_selector').on('change', function (e) {
    load_demonstrativos_valores($(this));
  });

  $('.remuneracao_devida_selector').trigger('change');

  $('.pais_pagamento_selector').on('change', function (e) {
    toggle_pagamento_exterior_fields($(this));
  });
  $('.pais_pagamento_selector').trigger('change');

  $('.tipo_previdencia_complementar_selector').on('change', function (e) {
    toggle_valor_patrocinador_previdencia_field($(this));
  });
  $('.tipo_previdencia_complementar_selector').trigger('change');

  $('.tipo_dependente_selector').on('change', function (e) {
    toggle_descricao_dependente_field($(this));
  });
  $('.tipo_dependente_selector').trigger('change');

  $(".dependente_deduz_base_selector").on('change', function (e) {
    toggle_tipo_dependente_fields($(this));
  });
  $(".dependente_deduz_base_selector").trigger('change');

  $('#replicacao_pagamento_form_competencia_origem_id').on('change', function (e) {
    load_pagamentos_por_competencia($(this).find('option:selected').val());
  });

  function load_pagamentos_por_competencia(competenciaOrigemId){
    let vinculos = [];
    let competenciaId = $('#replicacao_pagamento_form_competencia_id').val();
    $.ajax({
      type: "GET",
      dataType: 'script',
      url: '/competencias/' + competenciaId + '/pagamentos/replicar?competencia_origem_id=' + competenciaOrigemId
    }).done(function(data) {
      $('#replicacao_pagamento_form_competencia_origem_id').on('change', function (e) {
        load_remuneracoes_por_competencia($(this).find('option:selected').val());
      });
      $("#checkbox-toggle").click(function(){
        $('.checkable').prop('checked', this.checked);
      });
    });
  }
});

window.load_remuneracoes_devidas = function(target_elements) {
  if (!target_elements){
    target_elements = $(".remuneracao_devida_selector")
  }
  let remuneracoes_devidas = [];
  let pessoaId = $('#pagamento_pessoa_id').find('option:selected').val();
  if (pessoaId) {
    $.ajax({
      type: "GET",
      url: '/remuneracoes_devidas/por_pessoa?pessoa_id=' + pessoaId
    }).done(function(remuneracoes_devidas) {
      target_elements.each(function(){
        $(this).empty();
        $(this).select2({
          allowClear: true,
          width: '100%',
          language: 'pt-BR',
          placeholder: "Selecione...",
          data: remuneracoes_devidas
        }).val($(this).attr('val')).trigger('change');
      });
    });
  }
  else{
    target_elements.each(function(){
      $(this).empty();
      $(this).val(null).trigger('change');
    });
  }
}

window.load_demonstrativos_valores = function(remuneracao_devida_select) {
  let demonstrativos_valores = [];
  let remuneracaoDevidaId = remuneracao_devida_select.find('option:selected').val();
  let demonstrativoValoresSelector = remuneracao_devida_select.closest('.informacao_pagamento_fields').find('.demonstrativo_valores_selector');
  if (remuneracaoDevidaId) {
    $.ajax({
      type: "GET",
      url: '/demonstrativos_valores/por_remuneracao_devida?remuneracao_devida_id=' + remuneracaoDevidaId
    }).done(function(demonstrativos_valores) {
      demonstrativoValoresSelector.empty();
      demonstrativoValoresSelector.select2({
        allowClear: true,
        width: '100%',
        language: 'pt-BR',
        placeholder: "Selecione...",
        data: demonstrativos_valores
      }).val(demonstrativoValoresSelector.attr('val')).trigger('change');
    });
  }
  else{
    demonstrativoValoresSelector.empty();
    demonstrativoValoresSelector.val(null).trigger('change');
  }
}

window.toggle_pagamento_exterior_fields = function(pais_pagamento_selector) {
  let informacao_pagamento_fields = pais_pagamento_selector.closest('.informacao_pagamento_fields')
  let nacionalidade_selecionada = pais_pagamento_selector.find(':selected').text();
  if (nacionalidade_selecionada === '' || nacionalidade_selecionada === '105 - Brasil') {
    informacao_pagamento_fields.find(".pagamento_exterior_fields").hide();
    informacao_pagamento_fields.find(".nif_field").val(null);
    informacao_pagamento_fields.find(".tipo_nif_selector").val(null).trigger('change');
    informacao_pagamento_fields.find(".tipo_tributacao_exterior_selector").val(null).trigger('change');
  }
  else{
    informacao_pagamento_fields.find(".pagamento_exterior_fields").show();
  }
}

window.toggle_valor_patrocinador_previdencia_field = function(tipo_previdencia_complementar_selector) {
  let deducao_previdencia_complementar_fields = tipo_previdencia_complementar_selector.closest('.deducao_previdencia_complementar_fields')
  let previdencia_selecionada = tipo_previdencia_complementar_selector.find(':selected').text();
  if (previdencia_selecionada === '3 - Funpresp') {
    deducao_previdencia_complementar_fields.find(".valor_patrocinador_group_field").show();
  }
  else{
    deducao_previdencia_complementar_fields.find(".valor_patrocinador_group_field").hide();
    deducao_previdencia_complementar_fields.find(".valor_patrocinador_field").val(0);
    deducao_previdencia_complementar_fields.find(".valor_patrocinador_13_field").val(0);
  }
}

window.toggle_descricao_dependente_field = function(tipo_dependente_selector) {
  let dependente_fields = tipo_dependente_selector.closest('.dependente_fields')
  let tipo_selecionado = tipo_dependente_selector.find(':selected').text();
  if (tipo_selecionado === '99 - Agregado/Outros') {
    dependente_fields.find(".descricao_dependente_group_field").show();
  }
  else{
    dependente_fields.find(".descricao_dependente_group_field").hide();
    dependente_fields.find(".descricao_dependente_field").val(null);
  }
}

window.toggle_tipo_dependente_fields = function(radio_selector) {
  if (radio_selector.is(':checked')){
    let dependente_fields = radio_selector.closest('.dependente_fields');
    let deduz_base = radio_selector.val();
    if (deduz_base == 'false') {
      dependente_fields.find(".tipo_dependente_fields").hide();
      dependente_fields.find(".tipo_dependente_selector").val(null).trigger('change');
      dependente_fields.find(".descricao_dependente_field").val(null);
    } else {
      dependente_fields.find(".tipo_dependente_fields").show();
      toggle_descricao_dependente_field(dependente_fields.find(".tipo_dependente_selector"));
    }
  }
}
